.home-subscribe-container {
  position: relative;
  width: 80%;
  height: 50vh;
  margin: 5vh auto;
  padding: 8vh 0;
  background: #f3e6b4;
  font-display: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4vh;
  border-top-left-radius: 8vw;
  border-top-right-radius: 1vw;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 8px;
}
.vector-bg{
  width: 20vw;
  position: absolute;
  left: -10%;
  bottom: 0;
}
.vector-bg-1{
  width: 12vw;
  position: absolute;
  right: 2%;
  top: 0;
}

.top-arrow{
  width: 4vw;
  position: absolute;
  right: -2%;
  top: -5%;
}
.home-subscribe-container h1 {
    font-size: calc(4px + 2.75vw);
    font-weight: 500;
    line-height: 4vw;
    letter-spacing: 0;
    color: #525252;
    text-align: center;
}
.input-box-button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.susbcribe-btn {
  width: 8vw;
}
.input-container {
  display: flex;
  align-items: center;
  gap: 1vw;
  border: 1px solid #ccc;
  background: #fefefe;
  padding: 2px 4vw;
  border-radius: 1vw;
}

.input-container input[type="email"] {
  /* width: 300px; */
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.input-container img {
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
}


  .subscribe-container{
    background-color: #FFF9E0;
    border-radius: 8vw 4vw 1vw 1vw; 
  }
