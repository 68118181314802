.footer-container { 
    background: #000000;
    color: aliceblue;  
    width: 100%;
}
.footer-container h1{
  font-size: calc(4px + 1.1vw);
  font-weight: 600;
}
.footer-container p{
     font-size: calc(4px + .6vw);
}
.footer-link {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 2%;
    width: 100%;
    padding: 5vh 8vw;
    padding-right: 0;
    padding-bottom: 0;
}
.footer-sec-1{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4vh;
}
.footer-sec-1 img {
    width: 12vw;
}
.footer-sec-2, .footer-sec-3{
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3vh;
}
.footer-sec-2 p:hover, .footer-sec-3 p:hover{
    cursor: pointer;
    transform: scale(1.04);
    transition: 0.4s ease-in-out;
    text-decoration: underline
}
.footer-sec-4, .footer-sec-5{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2vh;
}


.footer-sec-4 span{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
}
.footer-sec-4 span img {
    width: calc(4px + 1vw);
}

.footer-sec-5 div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .2vw;
}
.footer-sec-5 div img:hover{
   cursor: pointer;
   transform: scale(1.1);
   transition: 0.3s ease all;
}
.footer-line {   
    display: block;
    width: 100%; /* Make the span element fill the entire width */
    height: 0.1px; /* Set the height to control the line thickness */
    background-color: #fefefe; /* Line color */
    margin: 10px 0; /* Adjust margin as needed */
    /* Additional styles for line appearance (optional) */
    border: none; /* Remove border */
}
.footer-text {
    /* border-top: 2px solid #ffffff; */
    font-size: calc(4px + 1.1vw);
    font-weight: 500;
    text-align: center;
    padding-bottom: 4vh;
}


